<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Inventory</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicTable
            v-model:rows="rows"
            v-model:items="items"
            :fix-height="true"
            :show-order="false"
            :show-pin="false"
            v-on:selected="(e) => (selectedItems = e)"
            type="products"
          />
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            v-if="selectedItems.length > 0"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
            v-on:click="addIncome"
          >
            Back to warehouse
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import DynamicTable from "../lists/DynamicTable";
import { useStore } from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "CrmItemInventoryModal",
  props: {
    modelValue: Object,
    show: Boolean,
    partner: Number,
  },
  emits: ["update:modelValue", "update:show"],
  data: function () {
    return {
      store: useStore(),
      loadAddBtn: false,
      rows: [
        {
          name: "Image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "product_quantity",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      selectedItems: [],
      items: undefined,
      myModal: null,
      page: 1,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.page = 1;
      this.load();
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
    const _this = this;
    this.$refs.modal.addEventListener("hidden.bs.modal", function () {
      _this.$emit("update:show", false);
    });
  },
  watch: {
    show() {
      if (this.show) {
        this.page = 1;
        this.load();
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
  },
  methods: {
    load() {
      if (this.page === 1) {
        this.items = [];
      }
      http
        .fetch("/crm/partners/inventory/" + this.partner + "?page=" + this.page)
        .then((data) => {
          if (data.data.length < 0) {
            this.hasMore = false;
          }
          if (this.page === 1) {
            for (const characterisics of data.characteristics) {
              this.rows.push({
                name: characterisics.name,
                key: "caracteristics_" + characterisics.id,
                type: "string",
                show: true,
                editable: false,
              });
            }
            this.items = [];
          }
          for (const row of data.data) {
            row.image =
              row.image !== null ? this.mediaServer + "/" + row.image : null;
            this.items.push(row);
          }
        });
    },
    loadMore() {
      if (this.hasMore) {
        this.page++;
        this.load();
      }
    },
    addIncome() {
      this.loadAddBtn = true;
      let incoming = {
        partner_id: this.partner,
        warehouse_id: this.warehouse,
        type: 9,
        items: [],
      };

      for (const product of this.selectedItems) {
        if (product.id !== null && product.id !== undefined) {
          incoming.items.push({
            id: product.product_variant_id,
            quantity: product.product_quantity,
          });
        }
      }

      http
        .fetch("/warehouses/income", incoming, true)
        .then((data) => {
          window.location = `/incoming/${data.id}`;
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: { DynamicTable },
};
</script>
