<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.noWarehouseSelected2") }}
    </div>
    <div
      v-else-if="selectedWarehouse?.fulfilment_app_id > 0"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.isFulfillment") }}
    </div>
    <div class="row gap-3" v-else>
      <div class="text-end">
        <button
          class="btn btn-type-2 btn-small"
          v-on:click="hideArchive"
          v-if="isArchive"
        >
          <span>Active inventories</span>
        </button>
        <button
          class="btn btn-type-4 btn-small"
          v-on:click="showArchive"
          v-else
        >
          <span>Archive inventories</span>
        </button>
      </div>
      <div class="mt-4 col-12" v-if="audits === undefined">
        <div class="nature-white-box no-min-height mb-3">
          <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.3"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.6"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.9"
          />
        </div>
      </div>
      <div v-else-if="audits.length === 0" class="text-center mt-4">
        <Vue3Lottie :animationData="ghostJSON" :height="160" :width="300" />
        <div class="bold-40 m-2 p-5">
          <div class="mb-4">No active inventory audit yet.</div>
          <button class="btn btn-type-4" v-on:click="createNew">
            <span>Create new</span>
          </button>
        </div>
      </div>
      <div v-else>
        <div
          class="nature-white-box no-min-height mb-3"
          v-for="audit in audits"
          :key="audit.id"
        >
          <div class="white-box-top"></div>
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>{{ formatDate(audit.planned_at ?? audit.created_at) }}</h2>
              <div>{{ audit.user.first_name }} {{ audit.user.last_name }}</div>
            </div>
            <div class="col-auto custom-input whitebg-input">
              <div class="input-group mb-1 px-0 py-2">
                <span class="input-group-text">
                  <button
                    class="btn btn-link"
                    v-on:click="
                      () => {
                        if (audit.packages > 1) {
                          audit.packages--;
                        }
                      }
                    "
                  >
                    <BaseIcon name="minus-circle" />
                  </button>
                  <input
                    type="number"
                    class="form-control w-4rem"
                    v-model="audit.packages"
                  />
                  <button
                    class="btn btn-link"
                    v-on:click="
                      () => {
                        audit.packages++;
                      }
                    "
                  >
                    <BaseIcon name="plus-circle" />
                  </button>
                </span>
              </div>
            </div>
            <div class="col-6 custom-input whitebg-input">
              <label class="input-group mb-1">
                <span class="input-group-text me-3">
                  <BaseIcon
                    :name="audit.show_success ? 'tick-circle' : 'scanner'"
                  />
                </span>
                <input
                  :placeholder="$t(`salesProductList.readBarcode`)"
                  type="text"
                  class="form-control"
                  v-model="audit.barcode"
                  :disabled="audit.disable"
                  :ref="'serach_box_' + audit.id"
                  v-on:change="scan(audit.id, $event.target.value)"
                />
              </label>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="audit.items"
            :show-del="false"
            :show-add="false"
            :show-edit="false"
            :show-tick="true"
            v-on:tick="(e) => setItemFinished(audit.id, e)"
            type="products"
          />
        </div>
      </div>
    </div>
    <ScanModal
      ref="packagingModal"
      :title="packagingInfo.status"
      :text="packagingInfo.text"
      :icon="packagingInfo.icon"
    />
    <NewInventoryAudit ref="createModal" v-on:add="loadData" />
    <ConfirmModal
      ref="noWarehouseModal"
      :title="$t(`outgoing.noWarehouseSelected`)"
      :text="$t('order.pleaseSelectAWarehouse')"
      :show-ok="false"
      cancel-button="Ok"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import http from "@/modules/http";
import date from "@/modules/date";
import ghostJSON from "../../assets/animations/ghost.json";
import { Vue3Lottie } from "vue3-lottie";
import NewInventoryAudit from "../../components/modals/NewInventoryAudit.vue";
import ConfirmModal from "../../components/modals/ConfirmModal.vue";
import OneByOneTable from "../../components/lists/OneByOneTable.vue";
import BaseIcon from "../../components/icons/BaseIcon.vue";
import { defineAsyncComponent } from "vue";
import ScanModal from "../../components/modals/ScanModal.vue";

export default {
  name: "inventoryAudit",
  components: {
    ScanModal,
    BaseIcon,
    OneByOneTable,
    ConfirmModal,
    NewInventoryAudit,
    Vue3Lottie,
  },
  data() {
    return {
      store: useStore(),
      ghostJSON: ghostJSON,
      audits: undefined,
      isArchive: false,
      auditsById: {},
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Counted quantity",
          key: "counted_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Prodyflow quantity",
          key: "product_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      packagingInfo: {
        icon: null,
        status: "",
        text: "",
      },
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    warehouse() {
      this.loadData();
    },
  },
  methods: {
    formatDate(val) {
      return date.format(val, true, false);
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    createNew() {
      if (this.warehouse === null) {
        this.$refs.noWarehouseModal.showModal();
      } else {
        this.$refs.createModal.showModal();
      }
    },
    showArchive() {
      this.audits = undefined;
      this.isArchive = true;
      this.loadData();
    },
    hideArchive() {
      this.audits = undefined;
      this.isArchive = false;
      this.loadData();
    },
    loadData() {
      if (this.warehouse === null || this.warehouse === undefined) {
        this.audits = undefined;
        return;
      }

      let url = `/warehouses/outgoing/inventory-audit?warehouse=${
        this.warehouse
      }&archive=${this.isArchive ? 1 : 0}`;
      http.fetch(url).then((data) => {
        for (const audit of data) {
          audit.packages = 1;
          for (const item of audit.items) {
            if (item.image !== null && item.image !== undefined) {
              item.image = this.mediaServer + "/" + item.image;
            }
          }
        }
        this.audits = data;
        this.auditsById = {};

        for (const audit of this.audits) {
          this.auditsById[audit.id] = audit;
        }
      });
    },
    scan(audit, barcode) {
      if (barcode === null && barcode.length < 3) {
        return;
      }

      this.auditsById[audit].barcode = "";
      this.auditsById[audit].disable = true;

      let element = this.auditsById[audit].items.find(
        (e) => e.sku === barcode || e.ean === barcode || e.upc === barcode
      );
      if (element !== undefined) {
        this.countItem(audit, element.id, this.auditsById[audit].packages);
      } else {
        this.packagingInfo.icon = 2;
        this.packagingInfo.status = "Barcode not found";
        this.packagingInfo.text =
          "We cannot find any item with this barcode in this transfer.";
        this.$refs.packagingModal.showModal();
        this.auditsById[audit].disable = false;
      }
    },
    countItem(audit, item, quantity) {
      http
        .fetch(`/warehouses/outgoing/inventory-audit/${audit}/set`, {
          item: item,
          quantity: quantity,
        })
        .then((data) => {
          let e = this.auditsById[audit].items.find((e) => e.id === item);
          if (e === undefined) {
            return;
          }
          e.counted_quantity = data.counted_quantity;

          this.packagingInfo.status = "Item counted";
          this.packagingInfo.text = "Item counted";
          this.packagingInfo.icon = 1;
          this.auditsById[audit].packages = 1;
          this.auditsById[audit].show_success = true;
          this.auditsById[audit].disable = false;
          setTimeout(() => {
            this.$refs["serach_box_" + audit][0].focus();
          }, 40);
          setTimeout(() => {
            this.auditsById[audit].show_success = false;
          }, 600);
          // this.$refs.packagingModal.showModal();
        })
        .catch((data) => {
          this.auditsById[audit].disable = false;
          this.packagingInfo.icon = 2;
          this.packagingInfo.status = "Error";
          this.packagingInfo.text = data.message;
          this.$refs.packagingModal.showModal();
        });
    },
    setItemFinished(audit, item) {
      http
        .fetch(
          `/warehouses/outgoing/inventory-audit/${audit}/finish`,
          {
            item: item.id,
          },
          true
        )
        .then(() => {
          let e = this.auditsById[audit].items.findIndex(
            (e) => e.id === item.id
          );
          if (e === undefined) {
            return;
          }
          this.auditsById[audit].items.splice(e, 1);
        });
    },
  },
};
</script>
