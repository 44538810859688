const products = {
  TYPE_PRODUCT: 1,
  TYPE_VARIANT: 2,
  TYPE_GROUP: 3,
  TYPE_MULTIPLE_PRODUCT: 4,
  products: null,
  languages: [],
  warehouses: [],
  ingredients: [],
  externals: [],
  byProductId: {},
  byVariantId: {},
  mediaServer: "",

  byVariantGroupTmp: {},
  variantVal: {},

  groupRows: [
    "purchase_price",
    "vendor_package",
    "orders_quantity",
    "incoming_quantity",
    "waiting_incoming_quantity",
    "total_stock",
    "rank",
    "end_date",
    "preorder",
    "abc_category",
    "operations_cost",
    "ingredients_price",
  ],
  avgRows: ["ingredients_price"],
  sumRows: [
    "orders_quantity",
    "incoming_quantity",
    "waiting_incoming_quantity",
    "total_stock",
  ],

  basicModel: {
    type: 0,
    id: 0,
    image: undefined,
    status: 0,
    serial: "",
    order: 0,
    sales_channel: undefined,
    release_data: undefined,
    end_date: undefined,
    preorder: undefined,
    category: undefined,
    description_filled: undefined,
    meta: undefined,
    weight: 0,
    description: undefined,
    tags: [],
  },

  characteristics: {},
  maincs_id: null,

  reset() {
    this.products = null;
    this.byProductId = {};
    this.byVariantId = {};
    this.byVariantGroupTmp = {};
    //this.characteristics = {};
  },

  addlanguages(languages) {
    this.languages = languages;
  },
  addCurrencies(currencies) {
    this.currencies = currencies;
    for (const currency of currencies) {
      this.groupRows.push(`sell_price_${currency}`);
      this.groupRows.push(`discount_price_${currency}`);
    }
  },

  addMediaServer(url) {
    this.mediaServer = url;
  },

  addIngredient(ingredient) {
    if (this.groupRows.indexOf(ingredient) < 0) {
      this.groupRows.push(ingredient);
      this.ingredients.push(ingredient);
      this.groupRows.push(
        ingredient.replace("ingredients_", "productComponentsLock_")
      );
    }
  },
  addExternal(external) {
    if (this.groupRows.indexOf(external) < 0) {
      this.groupRows.push(external);
      this.externals.push(external);
    }
  },

  addWarehouses(warehouses) {
    this.warehouses = warehouses;
    for (const w of warehouses) {
      if (this.sumRows.indexOf(`stock_${w.id}`) < 0) {
        this.groupRows.push(`stock_${w.id}`);
        this.sumRows.push(`stock_${w.id}`);
        this.groupRows.push(`minimum_stock_${w.id}`);
        this.groupRows.push(`is_sale_${w.id}`);
        this.groupRows.push(`safety_quantity_${w.id}`);
        this.groupRows.push(`optimal_order_quantity_${w.id}`);
        this.groupRows.push(`reorder_point_${w.id}`);
      }
    }
  },

  addCharacterics(characteristics) {
    for (const cs of characteristics) {
      this.groupRows.push("characteristics_" + cs.id);
      this.characteristics[cs.id] = {
        equal: true,
        val: null,
        type: cs.type,
      };
      if (cs.main_characteristics !== null) {
        this.maincs_id = cs.id;
      }
    }
  },
  getCommonMaterials(arr1, arr2) {
    let common = [];
    for (let i = 0; i < arr1?.length; ++i) {
      for (let j = 0; j < arr2?.length; ++j) {
        if (arr1[i].variant_id === arr2[j].variant_id) {
          common.push(arr1[i]);
        }
      }
    }

    return common;
  },
  setComponents(components, product) {
    if (components === undefined) {
      return;
    }
    if (typeof product !== "object") {
      product = this.byVariantId[product];
    }
    let materials = {};
    for (const ingredient of components) {
      if (materials[`ingredients_${ingredient.category_id}`] === undefined) {
        materials[`ingredients_${ingredient.category_id}`] = {
          type: products.TYPE_VARIANT,
          materials: [],
        };
      }

      for (const key in ingredient) {
        if (key.indexOf("caracteristics_") > -1) {
          if (ingredient["caracteristics"] === undefined) {
            ingredient["caracteristics"] = [];
          }
          ingredient["caracteristics"][key.replace("caracteristics_", "")] =
            ingredient[key];
        }
      }
      materials[`ingredients_${ingredient.category_id}`].materials.push(
        ingredient
      );
    }
    Object.assign(product, materials);
    if (product?.product) {
      Object.assign(product.product, materials); //todo if not every line / variant changed
    }
  },
  setCharacteristics(product, variant) {
    product.sku = variant.sku !== null ? variant.sku : undefined;
    product.ean = variant.ean !== null ? variant.ean : undefined;
    product.mpn = variant.mpn !== null ? variant.mpn : undefined;
    product.upc = variant.upc !== null ? variant.upc : undefined;
    product.variant_id = variant.id;

    product.productVariantManualRecommendations =
      variant.productVariantManualRecommendations !== null
        ? variant.productVariantManualRecommendations
        : undefined;

    if (variant?.productComponentsLock) {
      for (const productComponentsLock of variant?.productComponentsLock) {
        if (
          product[
            `productComponentsLock_${productComponentsLock.product_category_id}`
          ] === undefined
        ) {
          product[
            `productComponentsLock_${productComponentsLock.product_category_id}`
          ] = true;
        }
      }
    }

    product.orders_quantity =
      variant.orders_quantity !== null
        ? Number.parseInt(variant.orders_quantity)
        : 0;
    product.incoming_quantity =
      variant.incoming_quantity !== null
        ? Number.parseInt(variant.incoming_quantity)
        : 0;
    product.waiting_incoming_quantity =
      variant.waiting_incoming_quantity !== null
        ? Number.parseInt(variant.waiting_incoming_quantity)
        : 0;
    product.total_stock =
      variant.total_stock !== null ? Number.parseInt(variant.total_stock) : 0;

    product.image =
      variant.image !== null
        ? variant.image.indexOf("http") == 0
          ? variant.image
          : this.mediaServer + "/" + variant.image
        : null;
    product.vendor_package =
      variant.vendor_package !== null ? variant.vendor_package : undefined;
    product.rank = variant.rank !== null ? variant.rank : undefined;
    for (const external of this.externals) {
      product[external] = variant[external];
    }

    product.purchase_price = null;
    if (
      variant.productVariantPurchasePrices !== null &&
      variant.productVariantPurchasePrices !== undefined &&
      variant.productVariantPurchasePrices.length > 0
    ) {
      product.purchase_price = [];
      for (const price of variant.productVariantPurchasePrices) {
        product.purchase_price.push({
          amount: price.purchase_price,
          currency: price.currency_code,
          id: price.id,
          partner: {
            id: price.partner_id,
            name: price.partner_name,
          },
        });
      }
    }

    product["preorder"] = variant["preorder"];
    product["abc_category"] = variant["abc_category"];
    product["ingredients_price"] =
      variant["ingredients_price"] !== null && variant["ingredients_price"] > 0
        ? variant["ingredients_price"]
        : 0;
    product["operations_cost"] = variant["operations_cost"];
    product["end_date"] = variant["end_date"];
    product["stock"] = Number.parseInt(variant["stock"]);
    for (const warehouse of this.warehouses) {
      product[`stock_${warehouse.id}`] = Number.parseInt(
        variant[`stock_${warehouse.id}`]
      );
      product[`minimum_stock_${warehouse.id}`] =
        variant[`minimum_stock_${warehouse.id}`];
      product[`optimal_order_quantity_${warehouse.id}`] =
        variant[`optimal_order_quantity_${warehouse.id}`];
      product[`safety_quantity_${warehouse.id}`] =
        variant[`safety_quantity_${warehouse.id}`];
      product[`reorder_point_${warehouse.id}`] =
        variant[`reorder_point_${warehouse.id}`];
      product[`is_sale_${warehouse.id}`] =
        variant[`is_sale_${warehouse.id}`] !== null
          ? variant[`is_sale_${warehouse.id}`]
          : 0;
    }

    this.setComponents(variant.components, product);

    for (const currency of this.currencies) {
      product[`sell_price_${currency}`] = {
        currency: currency,
        amount:
          variant[`sell_price_${currency}`] === null
            ? undefined
            : Number.parseFloat(variant[`sell_price_${currency}`]),
      };
      product[`discount_price_${currency}`] = {
        currency: currency,
        amount:
          variant[`discount_price_${currency}`] === null
            ? undefined
            : Number.parseFloat(variant[`discount_price_${currency}`]),
      };
    }

    for (const cs of variant.productCharacteristics) {
      if (
        this.characteristics[
          cs.characteristicsValue.characteristics_type_id
        ] === undefined ||
        this.characteristics[
          cs.characteristicsValue.characteristics_type_id
        ] === null
      ) {
        continue;
      }
      if (
        this.characteristics[cs.characteristicsValue.characteristics_type_id]
          ?.type === "string" ||
        this.characteristics[cs.characteristicsValue.characteristics_type_id]
          ?.type === "color"
      ) {
        product[
          "characteristics_" + cs.characteristicsValue.characteristics_type_id
        ] = cs.characteristicsValue.id;
      } else if (
        this.characteristics[cs.characteristicsValue.characteristics_type_id]
          ?.type === "date" ||
        this.characteristics[cs.characteristicsValue.characteristics_type_id]
          ?.type === "datetime"
      ) {
        product[
          "characteristics_" + cs.characteristicsValue.characteristics_type_id
        ] = cs.characteristicsValue.val_date;
      } else {
        product[
          "characteristics_" + cs.characteristicsValue.characteristics_type_id
        ] =
          cs.characteristicsValue.val_double !== null
            ? cs.characteristicsValue.val_double
            : cs.characteristicsValue.val_int;
      }
    }
  },

  addProduct(data, firstPlace = false) {
    if (this.products === null) {
      this.products = [];
    }
    this.byVariantGroupTmp = {};
    let product = Object.assign({}, this.basicModel);
    product.status = data.status_id;
    for (const ingredient of this.ingredients) {
      product[ingredient] = { type: products.TYPE_PRODUCT, materials: [] };
    }
    product.id = data.id;
    product.image =
      data.image !== null
        ? data.image.indexOf("http") == 0
          ? data.image
          : this.mediaServer + "/" + data.image
        : null;

    product.tags = data.tags;
    let descFilled = 0;
    for (const language of this.languages) {
      product[`name_${language.code}`] =
        data[`name_${language.code}`] === null
          ? ""
          : data[`name_${language.code}`];

      descFilled += data[`description_filled_${language.code}`];
    }
    product.description_filled =
      descFilled === 0 ? 0 : descFilled < this.languages.length ? 2 : 1;

    product.serial = data.serial;
    product.is_manufacturing_operations_locked =
      data.is_manufacturing_operations_locked === 1 ||
      data.is_manufacturing_operations_locked;
    product.preorder = data.preorder !== null ? data.preorder : undefined;
    product.abc_category = data.abc_category;
    product.ingredients_price =
      data.ingredients_price !== null &&
      data.ingredients_price !== undefined &&
      data.ingredients_price > 0
        ? data.ingredients_price
        : 0;
    product.operations_cost = data.operations_cost;
    product.order = data.order;
    product.category_id = data.category_id;
    product.product_manufacturing_operations =
      data?.product_manufacturing_operations;
    product.unit = data?.unit;
    product.pinned = data.pinned;
    product.release_data =
      data.release_data !== null ? data.release_data : undefined;
    product.end_date = data.end_date !== null ? data.end_date : undefined;
    product.sales_channel =
      data.sales_channel !== null ? data.sales_channel : undefined;

    for (const row of this.sumRows) {
      product[row] = 0;
    }
    for (const row of this.avgRows) {
      product[row] = 0;
    }

    if (data.product_variants === undefined || data.product_variants === null) {
      product.type = this.TYPE_PRODUCT;
      if (firstPlace) {
        this.products.unshift(product);
      } else {
        this.products.push(product);
      }
      this.byProductId[product.id] = product;
    } else if (data.product_variants.length === 1) {
      product.type = this.TYPE_PRODUCT;
      product.variantId = data.product_variants[0].id;

      this.setCharacteristics(product, data.product_variants[0]);

      if (firstPlace) {
        this.products.unshift(product);
      } else {
        this.products.push(product);
      }
      this.byProductId[product.id] = product;
      this.byVariantId[data.product_variants[0].id] = product;
    } else if (data.product_variants.length > 1) {
      product.type = this.TYPE_MULTIPLE_PRODUCT;

      if (firstPlace) {
        this.products.unshift(product);
      } else {
        this.products.push(product);
      }
      this.byProductId[product.id] = product;

      for (const variant of data.product_variants) {
        this.addVariant(product, variant);
      }
    }
  },

  variantHandler: {
    get(target, prop) {
      if (
        typeof prop === "string" &&
        (prop === "status" ||
          prop.indexOf("name_") === 0 ||
          prop === "description" ||
          prop === "serial")
      ) {
        return target.product[prop];
      }

      if (
        products.maincs_id !== null &&
        target.type === products.TYPE_VARIANT &&
        target.group !== undefined &&
        prop === "characteristics_" + products.maincs_id
      ) {
        return target.group[prop];
      }

      if (prop === "barcode") {
        return target["sku"];
      }

      return target[prop];
    },
    set(target, prop, value) {
      if (
        typeof prop === "string" &&
        (prop === "status" ||
          prop.indexOf("name_") === 0 ||
          prop === "description" ||
          prop === "serial")
      ) {
        target.product[prop] = value;
        return true;
      }
      if (
        products.maincs_id !== null &&
        target.type === products.TYPE_VARIANT &&
        target.group !== undefined &&
        prop === "characteristics_" + products.maincs_id
      ) {
        target.group[prop] = value;
        return true;
      }

      let oldVar = target[prop];

      products.setGroupValues(target.group, value, prop, oldVar);
      products.setGroupValues(target.product, value, prop, oldVar, true);

      target[prop] = value;
      return true;
    },
  },

  setGroupValues(target, value, prop, oldVar = undefined, force = false) {
    if (target !== undefined && products.groupRows.indexOf(prop) > -1) {
      if (products.avgRows.indexOf(prop) > -1) {
        if (target[prop] === undefined || target[prop] === null) {
          target[prop] =
            value !== null && value !== undefined
              ? Math.round(Number.parseFloat(value) ?? 0)
              : 0;
        } else if (value !== null && value !== undefined) {
          target[prop] = Math.round(
            (target[prop] + Number.parseFloat(value) ?? 0) / 2
          ); //TODO currency check
        }
      } else if (products.sumRows.indexOf(prop) > -1) {
        if (target[prop] === undefined || target[prop] === null) {
          target[prop] =
            value !== null && value !== undefined
              ? Number.parseInt(value) ?? 0
              : 0;
        } else if (value !== null && value !== undefined) {
          target[prop] += Number.parseInt(value) ?? 0;
        }
      } else if (
        prop.indexOf("sell_price_") === 0 ||
        prop.indexOf("purchase_price") === 0 ||
        prop.indexOf("discount_price_") === 0
      ) {
        if (
          target[prop]?.currency !== value?.currency ||
          target[prop]?.amount !== value?.amount
        ) {
          let valueKey =
            value?.amount == null || value?.currency === null
              ? null
              : value?.amount + "" + value?.currency;

          if (
            typeof target[prop] === "object" &&
            target[prop]?.multiple !== undefined
          ) {
            if (target[prop].values[valueKey] === undefined) {
              target[prop].values[valueKey] = { value: value, count: 1 };
            } else {
              target[prop].values[valueKey].count++;
            }
          } else {
            let values = {};
            values[valueKey] = { value: value, count: 1 };
            target[prop] = { multiple: true, values: values };
            this.setGroupValues(target, target[prop], prop);
          }
        }
      } else if (
        prop.indexOf("ingredients_") === 0 &&
        prop !== "ingredients_price"
      ) {
        if (
          value !== undefined &&
          value !== null &&
          target[prop] !== undefined &&
          target[prop] !== null &&
          target[prop].type === 1
        ) {
          target[prop].materials = this.getCommonMaterials(
            target[prop]?.materials,
            value?.materials
          );
        } else if (value !== undefined) {
          target[prop] = Object.assign({}, value);
          target[prop].type = products.TYPE_PRODUCT;
        } else {
          target[prop] = { type: products.TYPE_PRODUCT, materials: [] };
        }
      } else {
        if (target[prop] !== value) {
          if (
            typeof target[prop] === "object" &&
            target[prop]?.multiple !== undefined
          ) {
            if (target[prop].values[value] === undefined) {
              target[prop].values[value] = { value: value, count: 1 };
            } else {
              target[prop].values[value].count++;
            }
            if (target[prop].values[oldVar] !== undefined) {
              target[prop].values[oldVar].count--;
            }
            if (force) {
              target[prop] = Object.assign({}, target[prop]);
            }
          } else {
            let values = {};
            values[target[prop]] = { value: target[prop], count: 1 };
            values[value] = { value: value, count: 1 };
            target[prop] = { multiple: true, values: values };
          }
        }
      }
    }
  },

  addVariant(product, data) {
    let variant = Object.assign({}, this.basicModel);

    variant.type = this.TYPE_VARIANT;
    variant.product = product;
    variant.id = data.id;
    this.setCharacteristics(variant, data);

    const proxy = new Proxy(variant, this.variantHandler);

    if (product.variants === undefined) {
      product.variants = [];

      for (const prop of this.groupRows) {
        if (products.sumRows.indexOf(prop) > -1) {
          if (product[prop] === undefined || product[prop] === null) {
            product[prop] =
              variant[prop] !== null && variant[prop] !== undefined
                ? Number.parseInt(variant[prop])
                : 0;
          } else if (variant[prop] !== null && variant[prop] !== undefined) {
            product[prop] += Number.parseInt(variant[prop]);
          }
        } else if (
          prop.indexOf("sell_price_") === 0 ||
          prop.indexOf("purchase_price") === 0 ||
          prop.indexOf("discount_price_") === 0
        ) {
          if (
            variant[prop]?.currency === null ||
            variant[prop]?.amount === null
          ) {
            product[prop] = {
              currency: null,
              amount: null,
            };
          } else {
            product[prop] = variant[prop];
          }
        } else {
          product[prop] = variant[prop];
        }
      }
    } else {
      for (const prop of this.groupRows) {
        products.setGroupValues(product, variant[prop], prop);
      }
    }

    if (this.maincs_id !== null) {
      if (
        this.byVariantGroupTmp[variant["characteristics_" + this.maincs_id]] ===
        undefined
      ) {
        let c = Object.assign({}, variant);
        c.type = this.TYPE_GROUP;
        c.id = 0;

        const proxy2 = new Proxy(c, this.variantHandler);

        product.variants.push(proxy2);
        this.byVariantGroupTmp[variant["characteristics_" + this.maincs_id]] =
          c;

        variant.group = c;

        c.variants = [];
        c.variants.push(proxy);
      } else {
        variant.group =
          this.byVariantGroupTmp[variant["characteristics_" + this.maincs_id]];

        for (const prop of this.groupRows) {
          products.setGroupValues(variant.group, variant[prop], prop);
        }

        this.byVariantGroupTmp[
          variant["characteristics_" + this.maincs_id]
        ].variants.push(proxy);
      }
      this.byVariantId[data.id] = proxy;
    } else {
      product.variants.push(proxy);
      this.byVariantId[data.id] = proxy;
    }
  },
};

export default products;
