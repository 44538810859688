<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Trolley box sizes</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-3">Size name</div>
            <div class="col-3">Length</div>
            <div class="col-3">Width</div>
            <div class="col-3">Height</div>
          </div>
          <div class="row" v-for="size in sizes" :key="size.id">
            <div class="col-3">{{ size.name }}</div>
            <div class="col-3">{{ size.length }}</div>
            <div class="col-3">{{ size.width }}</div>
            <div class="col-3">{{ size.height }}</div>
          </div>
          <h4 class="mt-4">Add new</h4>
          <div class="row mt-2">
            <div class="col-3">
              <input
                type="text"
                class="form-control"
                placeholder="Name"
                v-model="newRow.name"
              />
            </div>
            <div class="col-3">
              <input
                type="number"
                class="form-control"
                placeholder="Length"
                v-model="newRow.length"
              />
            </div>
            <div class="col-3">
              <input
                type="number"
                class="form-control"
                placeholder="Width"
                v-model="newRow.width"
              />
            </div>
            <div class="col-3">
              <input
                type="number"
                class="form-control"
                placeholder="Height"
                v-model="newRow.height"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="addAsset"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "TrolleyBoxSizes",
  props: {
    modelValue: Object,
    sizes: Array,
  },
  emits: ["update:modelValue", "add"],
  data() {
    return {
      myModal: undefined,
      success: false,
      loadAddBtn: false,
      newRow: {},
    };
  },
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    addAsset() {
      this.loadAddBtn = true;
      http
        .fetch("/trolleys/sizes", this.newRow, true)
        .then((data) => {
          this.loadAddBtn = false;
          this.$emit("add", data);
        })
        .catch(() => {
          this.loadAddBtn = false;
        });
    },
  },
  components: {},
};
</script>
