<template>
  <span
    v-if="!edit && !alwaysEdit && (value === undefined || value === null)"
    v-on:dblclick="edit = true"
  >
    (not set)
  </span>
  <span v-else-if="!edit && !alwaysEdit" v-on:dblclick="edit = true">
    <span class="me-2">
      <img
        :src="value?.image"
        v-if="value?.image !== null && value?.image !== undefined"
        class="product-image"
        alt=""
      />
      <BaseIcon v-else name="no-image" class="product-image" />
    </span>
    <span class="me-2">{{ value?.serial ?? " - " }}</span>
    <span v-for="char in value.caracteristics" :key="char" class="me-2">
      {{ char }}
    </span>
  </span>
  <v-select
    v-else
    v-model:options="products"
    v-on:search="(s, k) => fetchProducts(s, k)"
    v-model="value"
    :filterable="false"
    :placeholder="$t(`orderChange.selectProduct`)"
    v-on:focusin="edit = true"
    v-on:focusout="edit = false"
    append-to-body
    :dropdown-should-open="() => edit"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching && search.length > 2">
        {{ $t("orderChange.noProductsFoundFor") }} <em>{{ search }}</em
        >.
      </template>
      <span v-else>
        {{ $t("orderChange.startTypingToSearchForAProduct") }}</span
      >
    </template>
    <template v-slot:selected-option="option">
      <div class="row">
        <div class="col-auto px-0">
          <img
            :src="option?.image"
            v-if="option?.image !== null && option?.image !== undefined"
            class="product-image"
            alt=""
          />
          <BaseIcon v-else name="no-image" class="product-image" />
        </div>
        <div class="col">
          <div>
            <b>{{ option.name }}</b>
          </div>
          <div>
            <span
              v-for="char in option.caracteristics"
              :key="char"
              class="me-2"
            >
              {{ char }}
            </span>
            <span class="me-2">
              {{ option.serial }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:option="option">
      <span class="me-2">
        {{ option.serial }}
      </span>
      <span v-for="char in option.caracteristics" :key="char" class="me-2">
        {{ char }}
      </span>
      <b>{{ option.name }}</b>
    </template>
  </v-select>
</template>

<script>
import BaseIcon from "../../icons/BaseIcon.vue";
import http from "../../../modules/http";

export default {
  name: "ProductSelector",
  components: { BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Object,
    alwaysEdit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "products",
    },
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      value: this.modelValue,
      products: [],
      edit: false,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("update", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    fetchProducts(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();

      let data = { q: search };

      this.lastSearchId = lastSearchId;
      if (this.row?.key !== null && this.isNumeric(this.row?.key)) {
        data.category_id = this.row?.key;
      }
      if (this.status > 0) {
        data.status = this.status;
      }
      http
        .fetch(`/products/${this.type}/search`, data)
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            this.products = data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
  },
  emits: ["update:modelValue", "change", "update"],
};
</script>
