<template>
  <div class="nature-white-box">
    <h1 class="mb-4">Forecast</h1>
    <one-by-one-table
      v-model:rows="rows"
      v-model:items="data"
      :show-add="false"
      :show-edit="false"
      :show-del="false"
      type="products"
    />
  </div>
</template>

<script>
import http from "@/modules/http";
import { useStore } from "vuex";
import date from "../../modules/date";
import { defineAsyncComponent } from "vue";
import OneByOneTable from "../../components/lists/OneByOneTable.vue";

export default {
  name: "Forecast",
  components: { OneByOneTable },
  data() {
    return {
      store: useStore(),
      data: null,
      hasMore: true,
      loadMore: false,
      page: 1,
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "characteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "characteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Current stock",
          key: "stock",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Avg monthly sale",
          key: "avg_monthly_orders",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Sale forecast",
          key: "forecast",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Scheduled incoming",
          key: "incoming",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "End stock (forecast)",
          key: "end_stock",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "End stock (forecast min)",
          key: "end_stock_min",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "End stock (forecast max)",
          key: "end_stock_max",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "",
          key: "product_id",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Forecast.vue")
          ),
          type: "object",
          show: true,
          editable: false,
        },
      ],
    };
  },
  computed: {
    date() {
      return this.store.state.topBar.date;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    years() {
      return Math.min(
        new Date().getUTCFullYear() - this.date[0].getUTCFullYear() + 2,
        6
      );
    },
    q() {
      if (
        this.store.state.topBar.q === null ||
        this.store.state.topBar.q === undefined
      ) {
        return this.store.state.topBar.q;
      }
      return this.store.state.topBar.q
        .replace(this.product?.serial, "")
        .replace(this.product?.name, "")
        .trim();
    },
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.page = 1;
        this.loadData();
      },
    },
    q() {
      this.page = 1;
      this.loadData();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, false, false);
    },
    handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      if (
        scrollTop + clientHeight + 450 >= scrollHeight - 5 &&
        this.hasMore &&
        !this.loadMore
      ) {
        this.loadMore = true;
        this.page++;
        this.loadData();
      }
    },
    loadData() {
      this.loadMore = true;
      http
        .fetch(
          `/products/forecast?page=${this.page}&${
            this.q ? "&q=" + this.q : ""
          }${
            this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : ""
          }`
        )
        .then((data) => {
          for (const row of data.data) {
            if (row.image) {
              row.image =
                row.image.image.indexOf("http") === 0
                  ? row.image
                  : this.mediaServer + "/" + row.image;
            }
            if (
              row.stock < 1 ||
              (row.end_stock_max < 0 &&
                row.stock - row.avg_monthly_orders * 2 < 0) ||
              (row.end_stock < 0 && row.stock - row.avg_monthly_orders * 3 < 0)
            ) {
              row.emptyLine = true;
            }
            if (this.page > 1) {
              this.data.push(row);
            }
          }
          if (this.page === 1) {
            this.data = data.data;
          }
          if (data.data.length < 50) {
            this.hasMore = false;
          }
          this.loadMore = false;
        });
    },
  },
};
</script>
