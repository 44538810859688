<template>
  <h1 class="mb-4">{{ product?.serial }} {{ product?.name }}</h1>
  <div class="row">
    <div class="col-lg-4">
      <div class="col-12">
        <div class="white-box text-end">
          <div class="line-cart">
            <LineChart :chartData="incomeByDays" :options="options" />
          </div>
          <div class="text-area right">
            <h2 class="bold-19 text-gradient mb-2">
              {{ $t("productHistory.incoming") }}
            </h2>
            <h2 class="bold-40 text-gradient">{{ total?.income }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="col-12">
        <div class="white-box text-end">
          <div class="line-cart">
            <LineChart :chartData="outcomeByDays" :options="options" />
          </div>
          <div class="text-area right">
            <h2 class="bold-19 text-gradient mb-2">
              {{ $t("productHistory.outgoing") }}
            </h2>
            <h2 class="bold-40 text-gradient">{{ total?.outcome }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="col-12">
        <div class="white-box text-end">
          <div class="line-cart">
            <LineChart :chartData="ordersByDays" :options="options" />
          </div>
          <div class="text-area right">
            <h2 class="bold-19 text-gradient mb-2">{{ $t("pos.sales") }}</h2>
            <h2 class="bold-40 text-gradient">{{ total?.orders }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="warehouse === null || warehouse === undefined || !warehouse"
    class="m-5 p-5 text-center bold-40"
  >
    {{ $t("productHistory.noWarehouseSelected2") }}
  </div>
  <one-by-one-table
    v-else
    v-model:rows="rows"
    v-model:items="products"
    type="products"
    :show-add="false"
    :show-del="false"
  />
</template>

<script>
import OneByOneTable from "../../components/lists/OneByOneTable";
import { defineAsyncComponent } from "vue";
import { LineChart } from "vue-chart-3";
import http from "@/modules/http";
import { useStore } from "vuex";

export default {
  name: "ProductHistory",
  components: { OneByOneTable, LineChart },
  data() {
    return {
      store: useStore(),
      product: {},
      options: {
        padding: 0,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      },
      incomeByDays: {
        labels: [],
        datasets: [
          {
            label: "Current value",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      outcomeByDays: {
        labels: [],
        datasets: [
          {
            label: "Current value",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },
      ordersByDays: {
        labels: [],
        datasets: [
          {
            label: "Current value",
            data: [],
            lineTension: 0.45,
            borderColor: "rgba(160,85,224,1)",
            backgroundColor: "rgba(160,85,224,0.3)",
            fill: true,
          },
        ],
      },

      rows: [
        {
          name: "User",
          key: "user",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/User")
          ),
          show: true,
          editable: false,
        },
        {
          name: "Event",
          key: "event",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/EventID")
          ),
          show: true,
          editable: false,
        },
        {
          name: "Date",
          key: "created_at",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "Type",
          key: "operation_type",
          type: "enum",
          values: {
            1: { id: 1, name: "Transfer" },
            2: { id: 2, name: "Incoming" },
            3: { id: 3, name: "Outgoing" },
            4: { id: 4, name: "Inventory deficit" },
            5: { id: 5, name: "Material income" },
            6: { id: 6, name: "Production" },
            7: { id: 7, name: "Waste" },
            8: { id: 8, name: "Repair" },
            9: { id: 9, name: "Product back" },
            98: { id: 98, name: "Return" },
            99: { id: 99, name: "Sale" },
          },
          label: "name",
          show: true,
          editable: false,
        },
        {
          name: "Operation",
          key: "description",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1", //TODO tmp add backend
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Change",
          key: "change",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "New stock",
          key: "stock",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Price",
          key: "price",
          type: "price",
          show: true,
          editable: false,
        },
      ],
      products: undefined,
      total: {},
      filtersTimeOut: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    variantId() {
      return this.$route.params.variant_id;
    },
    date() {
      return this.store.state.topBar.date;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    q() {
      if (
        this.store.state.topBar.q === null ||
        this.store.state.topBar.q === undefined
      ) {
        return this.store.state.topBar.q;
      }
      return this.store.state.topBar.q
        .replace(this.product?.serial, "")
        .replace(this.product?.name, "")
        .trim();
    },
    filters() {
      return this.store.state.filters?.product_history;
    },
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.products = undefined;
        this.loadData();
      },
    },
    warehouse() {
      this.products = undefined;
      this.loadData();
    },
    q() {
      this.products = undefined;
      this.loadData();
    },
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.products = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
  },
  mounted() {
    let filterOptions = [];
    filterOptions.push({
      name: "Type",
      type: "string",
      key: "operation_type",
      value: {},
      values: [
        { id: 1, value: "Transfer" },
        { id: 2, value: "Incoming" },
        { id: 3, value: "Outgoing" },
        { id: 4, value: "Inventory deficit" },
        { id: 5, value: "Material income" },
        { id: 6, value: "Production" },
        { id: 7, value: "Waste" },
        { id: 8, value: "Repair" },
        { id: 9, name: "Product back" },
        { id: 98, value: "Return" },
        { id: 99, value: "Sale" },
      ],
    });

    this.$store.commit("setFilterPage", "product_history");
    this.$store.commit("setFilterOptions", filterOptions);
    this.$store.commit("setFilterOptionsStock", false);
    this.$store.commit("setFilterOptionsPrice", false);

    this.loadData();
  },
  methods: {
    loadData() {
      if (this.id === null || this.id === undefined) {
        if (this.rows.findIndex((e) => e.key === "serial") < 0) {
          this.rows.splice(6, 0, {
            name: "Serial",
            key: "serial",
            type: "string",
            show: true,
            editable: false,
          });
        }
      }

      let url = `/products/${this.id ? this.id + "/" : ""}history?1${
        this.warehouse ? "&warehouse=" + this.warehouse : ""
      }${this.q ? "&q=" + this.q : ""}${
        this.variantId ? "&variant=" + this.variantId : ""
      }${
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "")
      }`;

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&${filter}[]=${value}`;
            }
          } else {
            url += `&${filter}=${this.filters[filter].value}`;
          }
        }
      }

      http.fetch(url).then((data) => {
        this.product = data.product;
        this.products = data.rows;
        this.total = data.total;

        for (const product of this.products) {
          product.image =
            product.image !== null
              ? product.image.indexOf("http") === 0
                ? product.image
                : this.mediaServer + "/" + product.image
              : null;
        }

        let labels = [];
        let orders = [];
        let income = [];
        let outcome = [];
        for (const date in data.daily) {
          labels.push(date);
          orders.push(Number.parseFloat(data.daily[date]?.orders?.quantity));
          income.push(Number.parseFloat(data.daily[date]?.income?.quantity));
          outcome.push(Number.parseFloat(data.daily[date]?.outcome?.quantity));
        }
        this.ordersByDays.labels = labels;
        this.ordersByDays.datasets[0].data = orders;
        this.incomeByDays.labels = labels;
        this.incomeByDays.datasets[0].data = income;
        this.outcomeByDays.labels = labels;
        this.outcomeByDays.datasets[0].data = outcome;
      });
    },
  },
};
</script>
