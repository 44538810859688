import { createStore } from "vuex";
import http from "../modules/http";
import tags from "@/modules/tags";
import router from "../router";

const store = createStore({
  state: {
    logged: false,
    user: null,
    appVersion: new Date().toISOString(),
    token: null,
    sessionId: null,
    languages: [],
    currencies: [],
    countries: [],
    warehouses: [],
    enabledWarehouses: [],
    webshops: [],
    topBar: {
      q: "",
      date: [new Date(), new Date()],
      warehouse: null,
    },
    tags: [],
    modules: undefined,
    listOption: [],
    operations: [],
    pricePartners: [],
    appData: {},
    pageOption: undefined,
    showRightMenu: false,
    showSearchMenu: false,
    defaultApps: [],
    apps: [],
    filters: {},
    filterPage: "",
    filterOptions: [],
    filterOptionStock: false,
    filterOptionsPrice: true,
    apiError: null,
    apiSuccess: null,
    apiLoading: [],
    lastModal: null,
  },
  mutations: {
    login(state, data) {
      state.logged = true;
      state.token = data.token;
      state.user = data.user;
      state.languages = data.languages;
      state.currencies = data.currencies;
      state.countries = data.countries;
      state.warehouses = data.warehouses;
      state.enabledWarehouses = data.enabled_warehouses;
      state.webshops = data.webshops;
      state.tags = data.tags;
      state.modules = data.modules;
      state.sessionId = data.session_id;

      tags.storeInit();
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.sessionId = null;
      state.logged = false;
    },
    editUser(state, user) {
      state.user.email = user.email;
      state.user.first_name = user.first_name;
      state.user.last_name = user.last_name;
    },
    editAvatar(state, avatar) {
      state.user.avatar_url = avatar;
    },
    toggleRightMenu(state) {
      state.showRightMenu = !state.showRightMenu;
    },
    toggleSearchMenu(state) {
      state.showSearchMenu = !state.showSearchMenu;
    },
    setApiError(state, data) {
      state.apiError = data;
    },
    setApiSuccess(state, data) {
      state.apiSuccess = data;
    },
    setApiLoading(state, data) {
      let i = state.apiLoading.findIndex((e) => e.key === data.key);
      if (i < 0) {
        state.apiLoading.push(data);
      } else if (!data.loading) {
        state.apiLoading.splice(i, 1);
      }
    },
    showModal(state, data) {
      state.lastModal = data;
    },
    updateFilters(state, obj) {
      const filter = obj.filter;
      delete obj.filter;
      if (state.filters[state.filterPage] === undefined) {
        state.filters[state.filterPage] = {};
      }
      if (obj.value === null || obj.value === undefined) {
        delete state.filters[state.filterPage][filter];
      } else {
        state.filters[state.filterPage][filter] = obj;
      }
    },
    clearFilters(state) {
      state.filters[state.filterPage] = {};
    },
    setOperations(state, data) {
      state.operations = data;
    },
    setPricePartners(state, data) {
      state.pricePartners = data;
    },
    clearListOption(state) {
      state.listOption = [];
    },
    addListOption(state, data) {
      state.listOption.push(data);
    },
    setPageOption(state, data) {
      state.pageOption = data;
    },
    storeAppData(state, data) {
      if (state.appData[data.appId] === undefined) {
        state.appData[data.appId] = {};
      }
      state.appData[data.appId][data.key] = data.value;
    },
    setTopBar(state, data) {
      state.topBar[data.key] = data.value;
      if (
        data.key !== "q" &&
        data.notify !== false &&
        typeof data.value !== "object"
      ) {
        bc.postMessage({
          type: "topBar",
          key: data.key,
          value: data.value,
        });
      }
    },
    setFilterPage(state, data) {
      if (state.filters[data] === undefined) {
        state.filters[data] = {};
      }
      if (state.filterPage !== data) {
        state.filterPage = data;
      }
    },
    setFilterOptions(state, data) {
      state.filterOptions = data;
    },
    setFilterOptionsStock(state, data) {
      state.filterOptionStock = data;
    },
    setFilterOptionsPrice(state, data) {
      state.filterOptionsPrice = data;
    },
    addApp(state, app) {
      state.apps.push(app);
    },
    addDefaultApp(state, app) {
      state.defaultApps.push(app);
    },
    addTag(state, tag) {
      state.tags["products"].push(tag);
      state.tags["orders"].push(tag);
    },
    initialiseStore(state) {
      state.topBar.date[0].setDate(new Date().getDate() - 365);
      if (localStorage.getItem("store")) {
        const rows = JSON.parse(localStorage.getItem("store"));
        for (const rowsKey in rows) {
          state[rowsKey] = rows[rowsKey];
        }
      }
      if (state.token !== null) {
        http.fetch("/users/me").then((data) => {
          state.user = data.user;
          state.sessionId = data.session_id;
          state.languages = data.languages;
          state.currencies = data.currencies;
          state.countries = data.countries;
          state.warehouses = data.warehouses;
          state.enabledWarehouses = data.enabled_warehouses;
          state.webshops = data.webshops;
          state.tags = data.tags;
          state.modules = data.modules;

          tags.storeInit();

          if (data.tenant.fist_steps) {
            router.push("/first-steps");
          }
        });
      }
      setTimeout(function () {
        bc.postMessage({
          type: "newPageTopBar",
        });
      }, 200);
    },
  },
  actions: {},
  modules: {},
});

store.subscribe((mutation, state) => {
  let store = {
    logged: state.logged,
    showRightMenu: state.showRightMenu,
    token: state.token,
  };
  localStorage.setItem("store", JSON.stringify(store));
  localStorage.setItem("language", state.user?.language_code);
});

const bc = new BroadcastChannel("store");

bc.onmessage = (event) => {
  if (event.data.type === "newPageTopBar") {
    bc.postMessage({
      type: "topBar",
      key: "warehouse",
      value: store.state.topBar.warehouse,
    });
  } else if (event.data.type === "topBar") {
    if (store.state.topBar[event.data.key] !== event.data.value) {
      store.commit("setTopBar", {
        key: event.data.key,
        value: event.data.value,
        notify: false,
      });
    }
  }
};

export default store;
