<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <!--<button
          class="btn w-100 text-start"
          v-on:click="active = 'basic'"
          :class="active === 'basic' ? 'btn-primary' : 'btn-simple'"
        >
          Customer center
        </button>-->
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'help'"
          :class="active === 'help' ? 'btn-primary' : 'btn-simple'"
        >
          Help
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'return'"
          :class="active === 'return' ? 'btn-primary' : 'btn-simple'"
        >
          Return
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'deliveries'"
          :class="active === 'deliveries' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.deliveries") }}
        </button>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <h2>
        {{ $t("countries.returnsChange") }}
      </h2>
      <section v-if="active == 'basic'">
        <h3 class="mb-3 bold-19">Customer support menu items</h3>
        <OneByOneTable
          v-model:rows="customerMenuRows"
          v-model:items="customerMenuItems"
          type="Menu"
          :show-add="false"
          :show-edit="false"
          :show-del="false"
          v-on:edit="editDomain"
        />
      </section>
      <section v-if="active == 'return'">
        <h3 class="mb-3 bold-19">Return</h3>
        <div
          class="text-start custom-input whitebg-input row align-items-center"
        >
          <label class="form-label bold-12 text-gray col-8">
            Allow self-serve return service
          </label>
          <div class="col-4 mb-5">
            <label class="cstm-check d-flex" for="ct-1">
              <input type="checkbox" v-model="is_return" value="1" id="ct-1" />
              <span class="checkmark"></span>
            </label>
          </div>
          <label class="form-label bold-12 text-gray col-8">
            Allow shop return service
          </label>
          <div class="col-4 mb-5">
            <label class="cstm-check d-flex" for="ct-1">
              <input type="checkbox" v-model="is_return" value="1" id="ct-1" />
              <span class="checkmark"></span>
            </label>
          </div>
          <label class="form-label bold-12 text-gray col-8">
            Cancel Return if the parcel is not returned within this period
          </label>
          <div class="fix-end-input col-4">
            <input
              type="number"
              class="form-control"
              v-model="return_allowed_days"
              ref="imp"
              step="1"
              :min="1"
            />
            <span>days</span>
          </div>
        </div>
        <h3 class="mb-3 bold-19">Return form</h3>
        <div class="row align-self-start">
          <div
            class="col-auto p-0 m-0 mb-3"
            v-for="l in languages"
            :key="l.code"
          >
            <button
              class="btn btn-tab"
              :class="{ active: l.code === selectedLanguage }"
              v-on:click="selectedLanguage = l.code"
            >
              <span class="semibold-14">{{ l.name }}</span>
            </button>
          </div>
        </div>
        <div>
          <div class="bold-16 mb-3">Why are you returning this item?</div>
          <div class="row mb-3 bold-16">
            <div class="col-4">Answer</div>
            <div class="col-4">{{ selectedLanguage }}</div>
            <div class="col">Action</div>
            <div class="col-auto">
              <button class="btn btn-type-2 btn-small">
                <span> Add new answer </span>
              </button>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-4 text-gray">I don't like the item</div>
            <div class="col-4 text-gray">I don't like the item</div>
            <div class="col-4">Return item</div>
          </div>
          <div class="row mb-3">
            <div class="col-8 text-gray">
              I found something else I like more
            </div>
            <div class="col-4">Return item</div>
          </div>
          <div class="row mb-3">
            <div class="col-8 text-gray">Item didn't fit</div>
            <div class="col-4">Change variant</div>
          </div>
          <div class="row mb-3">
            <div class="col-8 text-gray">I didn't have a good experience</div>
            <div class="col-4">Return item</div>
          </div>
          <div class="row mb-3">
            <div class="col-8 text-gray">Item Doesn't look good</div>
            <div class="col-4">Return item</div>
          </div>
          <div class="row mb-3">
            <div class="col-8 text-gray">I received the wrong item</div>
            <div class="col-4">Open dispute</div>
          </div>
          <div class="row mb-3">
            <div class="col-8 text-gray">Something else / rather not tell</div>
            <div class="col-4">Return item</div>
          </div>
        </div>
      </section>
      <section v-if="active == 'deliveries'">
        <div>
          <h3 class="mb-3 bold-19">Full return</h3>
          <div>
            <h4 class="mb-3 bold-16">EU</h4>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >Full Refund of Transport Costs</span
                >
                <span class="d-block">
                  The customer is reimbursed for the entire transport cost
                  incurred during the purchase.
                </span>
              </span>
              <input type="radio" v-model="euType" :value="2" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >Refund of the Cheapest Transport Cost</span
                >
                <span class="d-block">
                  The customer is refunded an amount equivalent to the lowest
                  available transport option, regardless of the method they
                  originally selected.
                </span>
                <span class="d-block">
                  The Consumer Rights Directive states businesses only need to
                  refund standard delivery costs, even if customers choose
                  non-standard, more expensive methods.
                </span>
              </span>
              <input type="radio" v-model="euType" :value="3" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >No Refund of the Transport Cost</span
                >
                <span class="d-block">
                  The customer bears the full transport cost, and no refund is
                  provided.
                </span>
                <span class="d-block">
                  <b>Warning</b>: No refund is allowed only for special products
                  like custom-made or perishable goods (Article 16, EU
                  Directive).
                </span>
              </span>
              <input type="radio" v-model="euType" :value="1" /><span
                class="checkmark"
              ></span>
            </label>
          </div>
          <div>
            <h3 class="mb-3 bold-16">Other countries</h3>
            <div class="row mb-3 bold-16">
              <div class="col-3">Country</div>
              <div class="col-3 text-center">Full refund</div>
              <div class="col-3 text-center">Cheapest refund</div>
              <div class="col-3 text-center">No Refund</div>
            </div>
            <div
              v-for="country in exportCountries"
              :key="country.id"
              class="row mb-1"
            >
              <div class="col-3">
                {{ country.name }}
              </div>
              <div class="col-3 justify-content-center d-flex">
                <label class="cstm-check">
                  <input
                    type="radio"
                    v-model="countryType[country.id].tax_option"
                    :value="4"
                  /><span class="checkmark"></span>
                </label>
              </div>
              <div class="col-3 justify-content-center d-flex">
                <label class="cstm-check">
                  <input
                    type="radio"
                    v-model="countryType[country.id].tax_option"
                    :value="5"
                  /><span class="checkmark"></span>
                </label>
              </div>
              <div class="col-3 justify-content-center d-flex">
                <label class="cstm-check">
                  <input
                    type="radio"
                    v-model="countryType[country.id].tax_option"
                    :value="99"
                  /><span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h3 class="mb-3 bold-19">Partial return</h3>
          <div>
            <h4 class="mb-3 bold-16">EU</h4>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >Full Refund of Transport Costs</span
                >
                <span class="d-block">
                  The customer is reimbursed for the entire transport cost
                  incurred during the purchase.
                </span>
              </span>
              <input type="radio" v-model="euType" :value="2" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >Refund of the Cheapest Transport Cost</span
                >
                <span class="d-block">
                  The customer is refunded an amount equivalent to the lowest
                  available transport option, regardless of the method they
                  originally selected.
                </span>
                <span class="d-block">
                  The Consumer Rights Directive states businesses only need to
                  refund standard delivery costs, even if customers choose
                  non-standard, more expensive methods.
                </span>
              </span>
              <input type="radio" v-model="euType" :value="3" /><span
                class="checkmark"
              ></span>
            </label>
            <label class="cstm-check d-flex gap-2 mb-3">
              <span class="text-black mx-2 regular-14">
                <span class="d-block bold-16"
                  >No Refund of the Transport Cost</span
                >
                <span class="d-block">
                  The customer bears the full transport cost, and no refund is
                  provided.
                </span>
                <span class="d-block">
                  In cases where the return does not involve withdrawal from the
                  contract, the EU does not mandate transport cost
                  reimbursement.
                </span>
              </span>
              <input type="radio" v-model="euType" :value="1" /><span
                class="checkmark"
              ></span>
            </label>
          </div>
          <div>
            <h3 class="mb-3 bold-16">Other countries</h3>
            <div class="row mb-3 bold-16">
              <div class="col-3">Country</div>
              <div class="col-3 text-center">Full refund</div>
              <div class="col-3 text-center">Cheapest refund</div>
              <div class="col-3 text-center">No Refund</div>
            </div>
            <div
              v-for="country in exportCountries"
              :key="country.id"
              class="row mb-1"
            >
              <div class="col-3">
                {{ country.name }}
              </div>
              <div class="col-3 justify-content-center d-flex">
                <label class="cstm-check">
                  <input
                    type="radio"
                    v-model="countryType[country.id].tax_option"
                    :value="4"
                  /><span class="checkmark"></span>
                </label>
              </div>
              <div class="col-3 justify-content-center d-flex">
                <label class="cstm-check">
                  <input
                    type="radio"
                    v-model="countryType[country.id].tax_option"
                    :value="5"
                  /><span class="checkmark"></span>
                </label>
              </div>
              <div class="col-3 justify-content-center d-flex">
                <label class="cstm-check">
                  <input
                    type="radio"
                    v-model="countryType[country.id].tax_option"
                    :value="99"
                  /><span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import OneByOneTable from "../lists/OneByOneTable.vue";

export default {
  name: "Returns",
  components: { OneByOneTable },
  data() {
    return {
      active: "return",
      euType: 1,
      selectedLanguage: "en",
      countryType: {},
      customerMenuItems: [
        {
          name: "Order status tracking",
          group: 1,
          enabled: true,
        },
        {
          name: "Variant change",
          group: 1,
          enabled: true,
        },
        {
          name: "Product change",
          group: 1,
          enabled: true,
        },
        {
          name: "Delete order",
          group: 1,
          enabled: true,
        },
        {
          name: "Return product",
          group: 1,
          enabled: true,
        },
        {
          name: "Address change",
          group: 1,
          enabled: true,
        },
        {
          name: "Help with something else",
          group: 1,
          enabled: true,
        },
        {
          name: "Report a complaint",
          group: 1,
          enabled: true,
        },
      ],
      customerMenuRows: [
        {
          name: "Option",
          key: "name",
          type: "enum",
          label: "name",
          selectKey: "id",
          values: [],
          show: true,
          editable: false,
        },
        {
          name: "Group",
          key: "group",
          type: "enum",
          label: "name",
          selectKey: "id",
          values: [
            { id: 1, name: "Manage order" },
            { id: 2, name: "Package pickup and delivery" },
            { id: 3, name: "Return" },
            { id: 4, name: "More" },
          ],
          show: true,
        },
        {
          name: "Enabled",
          key: "enabled",
          type: "boolean",
          show: true,
        },
      ],
    };
  },
  computed: {
    languages() {
      const store = useStore();
      return store.state.languages;
    },
    activeCountries() {
      const store = useStore();
      return store.state.countries;
    },
    exportCountries() {
      return this.activeCountries.filter((e) => !e.is_eu);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      for (const country of this.activeCountries) {
        this.countryType[country.id] = {};
      }
    },
  },
};
</script>
