<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sizes</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 whitebg-input">
              <label class="form-label bold-12 text-gray"> Slot name </label>
              <input class="form-control" type="text" v-model="name" />
            </div>
            <h5 class="mt-3">Slot size</h5>
            <div class="failed badge col-12" v-if="error.length > 0">
              {{ error }}
            </div>
            <div class="col-4 whitebg-input">
              <label class="form-label bold-12 text-gray"> Length </label>
              <input
                class="form-control"
                type="number"
                v-model="length"
                v-on:change="calcSizes"
              />
            </div>
            <div class="col-4 whitebg-input">
              <label class="form-label bold-12 text-gray"> Width </label>
              <input
                class="form-control"
                type="number"
                v-model="width"
                v-on:change="calcSizes"
              />
            </div>
            <div class="col-4 whitebg-input">
              <label class="form-label bold-12 text-gray"> Height </label>
              <input
                class="form-control"
                type="number"
                v-model="height"
                v-on:change="
                  () => {
                    minBoxSize.height = Math.max(2, Math.floor(height / 4));
                    calcSizes();
                  }
                "
              />
            </div>
            <h5 class="mt-3">Minimum package size</h5>
            <div class="col-4 whitebg-input">
              <label class="form-label bold-12 text-gray"> Length </label>
              <input
                class="form-control"
                type="number"
                v-model="minBoxSize.length"
                v-on:change="calcSizes"
              />
            </div>
            <div class="col-4 whitebg-input">
              <label class="form-label bold-12 text-gray"> Width </label>
              <input
                class="form-control"
                type="number"
                v-model="minBoxSize.width"
                v-on:change="calcSizes"
              />
            </div>
            <div class="col-4 whitebg-input">
              <label class="form-label bold-12 text-gray"> Height </label>
              <input
                class="form-control"
                type="number"
                v-model="minBoxSize.height"
                v-on:change="calcSizes"
              />
            </div>
            <h5 class="mt-3">Possible capacities</h5>
            <div class="row col-4" v-for="row in results" :key="row">
              <div class="col-6">
                {{ row.length }}x{{ row.width }}x{{ row.height }}
              </div>
              <div class="col-6">
                {{ row.capacity }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="save"
            :class="{ 'loading-spin': loadAddBtn }"
            :disabled="loadAddBtn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const bootstrap = require("bootstrap");

export default {
  name: "SizeEditor",
  props: {
    modelValue: Array,
  },
  emits: ["save"],
  data() {
    return {
      myModal: null,
      success: false,
      loadAddBtn: false,
      width: undefined,
      height: undefined,
      length: undefined,
      minBoxSize: { width: 2, height: 2, length: 2 },
      results: [],
      name: "",
    };
  },
  computed: {
    error() {
      if (this.length < this.width) {
        return "Length must be greater than width";
      }
      if (this.width < this.height) {
        return "Width must be greater than height";
      }
      if (this.length < this.height) {
        return "Length must be greater than height";
      }

      return "";
    },
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
  },
  methods: {
    showModal() {
      this.myModal.show();
    },
    calcSizes() {
      if (
        this.width === undefined ||
        this.height === undefined ||
        this.length === undefined ||
        this.minBoxSize.width === undefined ||
        this.minBoxSize.height === undefined ||
        this.minBoxSize.length === undefined
      ) {
        return;
      }

      this.results = [];

      let lastWidth = 0;

      for (let width = this.width; width >= this.minBoxSize.width; width--) {
        if (Math.floor(this.width / width) <= lastWidth) continue;

        lastWidth = Math.floor(this.width / width);
        let lastHeight = 0;

        for (
          let height = this.height;
          height >= this.minBoxSize.height;
          height--
        ) {
          if (Math.floor(this.height / height) <= lastHeight) continue;

          lastHeight = Math.floor(this.height / height);
          let lastLength = 0;

          for (
            let length = this.length;
            length >= this.minBoxSize.length;
            length--
          ) {
            if (Math.floor(this.length / length) <= lastLength) continue;
            if (length < width || length < height || width < height) continue;

            lastLength = Math.floor(this.length / length);
            let capacity = lastLength * lastWidth * lastHeight;
            this.results.push({ width, height, length, capacity });
          }
        }
      }
    },
    save() {
      this.$emit("save", {
        name: this.name,
        width: this.width,
        height: this.height,
        length: this.length,
        minBoxSize: this.minBoxSize,
        capacities: this.results,
      });
      this.myModal.hide();
    },
  },
};
</script>
