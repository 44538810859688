<template>
  <div class="d-flex align-items-center">
    <div>
      <span class="badge badge-outline me-2" v-for="v in value" :key="v.name">
        {{ v.name }}:
        <span class="fw-bold rounded-circle"> {{ v.total }}</span>
      </span>
    </div>
    <button class="btn btn-secondary btn-sm" title="Edit" v-on:click="edit">
      <BaseIcon name="edit" />
    </button>
  </div>
</template>

<script>
import BaseIcon from "../../icons/BaseIcon.vue";

export default {
  name: "TrolleyPacks",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    edit() {
      window.apps.callEvent("trolleys:packEdit", this.id);
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
