<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("manufacturingCollectList.noWarehouseSelected2") }}
    </div>
    <div
      v-else-if="selectedWarehouse?.type !== 2"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("manufacturingCollectList.thisWarehouseNotAProductionType") }}
    </div>
    <div class="row gap-3" v-else>
      <div class="text-end">
        <button
          v-on:click="showAddNewItem"
          class="btn btn-outline-primary btn-small me-2"
          v-if="!operationId"
        >
          <span
            ><BaseIcon name="additem" />
            {{ $t("manufacturingCollectList.addExtraItems") }}</span
          >
        </button>
        <button
          v-on:click="loadData"
          class="btn btn-outline-primary btn-small me-2"
        >
          <span
            ><BaseIcon name="refresh" />
            {{ $t("manufacturingCollectList.refresh") }}</span
          >
        </button>
        <button
          v-on:click="createCollection"
          class="btn btn-primary btn-small"
          :class="{ 'loading-spin': loadAddBtn }"
          :disabled="loadAddBtn"
        >
          <span
            ><BaseIcon name="plus" />
            {{ $t("manufacturingCollectList.createNew") }}</span
          >
        </button>
      </div>

      <div class="col-12" v-if="orders === undefined">
        <div
          v-for="i in 3"
          :key="i"
          class="nature-white-box no-min-height mb-3"
        >
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>
                <Skeletor :width="Math.random() * 21 + 30 + '%'" />
              </h2>
              <div>
                <span>
                  <Skeletor :width="Math.random() * 10 + 10 + '%'" />
                </span>
              </div>
            </div>
          </div>
          <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.3"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.6"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.9"
          />
        </div>
      </div>
      <div v-else class="col-12">
        <div v-if="orders.length === 0" class="m-5 p-5 text-center bold-40">
          {{ $t("manufacturingCollectList.thereIsNoCollectionList") }}
        </div>
        <div
          class="nature-white-box no-min-height"
          v-for="order in filteredOrders"
          :key="order.id"
        >
          <div class="white-box-top"></div>
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>{{ formatDate(order.created_at) }}</h2>
              <div>
                <span>#{{ order.id }}</span>
              </div>
            </div>
            <div class="col-auto mt-2">
              <!--<button
                class="btn btn-outline-primary p-2 me-2"
                data-bs-placement="top"
                title="Set collected"
                :disabled="order.loadShipBtn"
                v-on:click="setCollected(order)"
                :class="{ 'loading-spin': order.loadShipBtn }"
              >
                <BaseIcon name="tick-circle" />
              </button>-->
              <button
                class="btn btn-primary p-2"
                data-bs-placement="top"
                :title="$t(`manufacturingCollectList.printCollectionList`)"
                :disabled="order.loadShipBtn"
                v-on:click="downloadPdf(order.id)"
                :class="{ 'loading-spin': order.loadShipBtn }"
              >
                <BaseIcon name="printer" />
              </button>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="order.WarehouseCollectionItems"
            :show-del="false"
            :show-add="false"
            :show-edit="false"
            type="products"
            v-on:update:field="(e, f, g) => updateCollected(order, e, f, g)"
          />
        </div>
      </div>
    </div>
    <Modal ref="AddNewItem" :title="$t(`manufacturingCollectList.addNewItem`)">
      <ProductSelector
        :always-edit="true"
        type="materials"
        v-model="addItem"
        :status="1"
      />
      <label class="form-label bold-12 text-gray">
        {{ $t("ingredients.quantity") }}
      </label>
      <input
        type="number"
        class="form-control"
        min="0"
        v-model="addItemQuantity"
      />
      <div class="mt-1 text-end">
        <button class="btn btn-primary" v-on:click="addExtraItem">
          {{ $t("manufacturingCollectList.addItem") }}
        </button>
      </div>
      <p>
        {{
          $t(
            "manufacturingCollectList.thisItemWillBeAddedToTheLastCollectingListAsAn"
          )
        }}
        <b>{{ $t("manufacturingCollectList.extraMaterial") }}</b
        >.
      </p>
      <p>
        {{
          $t(
            "manufacturingCollectList.thisFunctionCanBeUsedToFillAnyShortfallsThatMayHav"
          )
        }}
      </p>
    </Modal>
  </div>
</template>
<script>
import OneByOneTable from "../../components/lists/OneByOneTable";
import http from "@/modules/http";
import { defineAsyncComponent } from "vue";
import BaseIcon from "@/components/icons/BaseIcon";
import ws from "@/modules/ws";
import { useStore } from "vuex";
import store from "@/store";
import date from "@/modules/date";
import tenant from "../../modules/tenant";
import Modal from "../../components/modals/Modal.vue";
import ProductSelector from "../../components/lists/listItems/ProductSelector.vue";

export default {
  name: "ManufacturingCollectList",
  components: { ProductSelector, Modal, BaseIcon, OneByOneTable },
  data() {
    return {
      store: useStore(),
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: true,
        },
        {
          name: "Stock when created",
          key: "product_stock",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Collected",
          key: "collected",
          type: "boolean",
          show: true,
          editable: true,
        },
      ],
      orders: undefined,
      itemsById: {},
      ordersById: {},
      loadAddBtn: false,
      firstLoad: true,
      addItem: null,
      addItemQuantity: 1,
    };
  },
  computed: {
    step() {
      return this.$route.params.step;
    },
    operationId() {
      return this.$route.params.id ?? null;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    q() {
      return this.store.state.topBar.q;
    },
    filteredOrders() {
      if (this.q === null || this.q.length < 2) {
        return this.orders;
      }
      let orders = Object.values(Object.assign({}, this.orders));
      orders = orders.filter(
        (e) =>
          e.WarehouseCollectionItems.findIndex(
            (g) =>
              g.sku?.indexOf(this.q) === 0 ||
              g.name?.toLowerCase().indexOf(this.q) > -1 ||
              g.serial?.toLowerCase().indexOf(this.q) > -1 ||
              g.warehouse_operation_id === this.q
          ) > -1
      );

      for (const i in orders) {
        orders[i].WarehouseCollectionItems = orders[
          i
        ].WarehouseCollectionItems.filter(
          (g) =>
            g.sku?.indexOf(this.q) === 0 ||
            g.name?.toLowerCase()?.indexOf(this.q) > -1 ||
            g.serial?.toLowerCase()?.indexOf(this.q) > -1 ||
            g.warehouse_operation_id === this.q
        );
      }

      return orders;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
  },
  mounted() {
    ws.init();

    ws.subscribe("modify", "productionCollect", (e) => {
      if (
        e.rowKey === "collected" ||
        e.rowKey === "in_package" ||
        e.rowKey === "quantity" ||
        e.rowKey === "need_to_sent_quantity"
      ) {
        let obj = this.itemsById[e.objectId];
        if (obj !== undefined) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    this.loadData();
  },
  methods: {
    showAddNewItem() {
      this.$refs.AddNewItem.showModal();
    },
    formatDate(val) {
      return date.format(val, true, false);
    },
    downloadPdf(id) {
      window.open(
        `${tenant.getApiBaseUrl()}/production/collect/pdf?id=${id}&_token=${
          store.state.token
        }`,
        "_blank"
      );
    },
    setCollected(order) {
      order.loadShipBtn = true;
      http
        .fetch("/production/collect/set-collected", { id: order.id }, false)
        .then(() => {
          order.loadShipBtn = false;
        })
        .catch(() => {
          order.loadShipBtn = false;
        });
    },
    setRemovedItem(order, item) {
      order.loadShipBtn = true;
      http
        .fetch(
          "/production/collect/remove-collected/item",
          { id: order.id, item: item.item_id },
          true
        )
        .then(() => {
          order.loadShipBtn = false;
        })
        .catch(() => {
          order.loadShipBtn = false;
        });
    },
    updateQuantity(order, item, quantity) {
      order.loadShipBtn = true;
      http
        .fetch(
          "/production/collect/change-quantity/item",
          { id: order.id, item: item.item_id, quantity: quantity },
          true
        )
        .then(() => {
          order.loadShipBtn = false;
        })
        .catch(() => {
          order.loadShipBtn = false;
        });
    },
    updateCollected(order, item, row, val) {
      if (row === "collected") {
        if (val == 1) {
          this.setCollectedItem(order, item);
        } else {
          this.setRemovedItem(order, item);
        }
      } else if (row === "quantity") {
        this.updateQuantity(order, item, val);
      }
    },
    setCollectedItem(order, item) {
      order.loadShipBtn = true;
      http
        .fetch(
          "/production/collect/set-collected/item",
          { id: order.id, item: item.item_id },
          false
        )
        .then(() => {
          order.loadShipBtn = false;
        })
        .catch(() => {
          order.loadShipBtn = false;
        });
    },
    addExtraItem() {
      this.loadAddBtn = true;
      http
        .fetch(
          "/production/collect/add-extra",
          {
            warehouse: this.warehouse,
            manufacturing_material_step: this.step,
            item: this.addItem?.variant_id,
            quantity: this.addItemQuantity,
          },
          false
        )
        .then(() => {
          this.loadData();
          this.$refs.AddNewItem.hideModal();
          this.loadAddBtn = false;
        })
        .catch((e) => {
          store.commit("setApiError", e);
          this.loadAddBtn = false;
        });
    },
    createCollection() {
      this.loadAddBtn = true;
      http
        .fetch(
          "/production/collect",
          {
            warehouse: this.warehouse,
            manufacturing_material_step: this.step,
            warehouse_operation: this.operationId ?? "",
          },
          false
        )
        .then(() => {
          this.loadData();
          this.loadAddBtn = false;
        })
        .catch((e) => {
          store.commit("setApiError", e);
          this.loadAddBtn = false;
        });
    },
    loadData() {
      this.orders = undefined;
      if (this.warehouse === undefined || this.warehouse === null) {
        return;
      }
      http
        .fetch(
          `/production/collect?warehouse=${
            this.warehouse
          }&manufacturing_material_step=${this.step}&warehouse_operation=${
            this.operationId ?? ""
          }`
        )
        .then((data) => {
          if (this.firstLoad) {
            let i = 0;
            for (const cs of data.characteristics) {
              this.rows.splice(3 + i, 0, {
                name: cs.name,
                key: "characteristics_" + cs.id,
                type: "string",
                show: true,
                editable: false,
              });
              i++;
            }
            this.firstLoad = false;
          }
          this.orders = data.data;
          this.ordersById = {};
          for (const key in this.orders) {
            this.ordersById[this.orders[key].id] = this.orders[key];
            for (const itemKey in this.ordersById[this.orders[key].id]
              .WarehouseCollectionItems) {
              let item = this.orders[key].WarehouseCollectionItems[itemKey];
              this.itemsById[
                this.orders[key].WarehouseCollectionItems[itemKey].id
              ] = item;

              if (item.image !== null && item.image !== undefined) {
                item.image = this.mediaServer + "/" + item.image;
              }
            }
          }
        });
    },
  },
};
</script>
