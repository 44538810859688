<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              path="products::related"
              :show-languages="false"
              :show-currencies="false"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              v-on:update:val="valUpdated"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              type="relatedProducts"
              v-on:loadMore="loadMore"
              :order="order"
              :fix-height="true"
              :key="tableKey"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from "../../components/lists/TopBar";
import http from "../../modules/http";
import DynamicTable from "../../components/lists/DynamicTable";
import { useStore } from "vuex";
import { defineAsyncComponent } from "vue";
import ws from "../../modules/ws";

export default {
  name: "RelatedProducts",
  components: { DynamicTable, TopBar },
  data() {
    return {
      store: useStore(),
      firstLoad: true,
      tableKey: 0,
      addPartner: false,
      rows: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      itemsById: {},
      selectedItems: [],
      page: 1,
      newCustomRowValue: undefined,
      listOptions: [],
      statuses: [],
      order: {
        row: null,
        desc: true,
      },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    filters() {
      return this.store.state.filters?.relatedProducts;
    },
    q() {
      return this.store.state.topBar.q;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    languages() {
      return this.store.state.languages;
    },
  },
  watch: {
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.loadData();
      }, 450);
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "relatedProducts");
    this.loadData();

    ws.init();

    ws.subscribe("modify", "relatedProduct", (e) => {
      if (e.objectId !== this.id) {
        return;
      }
      this.itemsById[e.rowKey][e.value.category_id] = e.value;
      this.tableKey++;
    });
  },
  methods: {
    loadMore() {
      this.page++;
      // this.loadData();
    },
    valUpdated(val, row, id, items, after = null) {
      if (row.indexOf("product_") === 0) {
        console.log("asd");
        let products = {};
        let product_variants_related_page_id =
          items.id !== undefined ? items.id : null;
        let startItem = null;
        for (let [key, item] of Object.entries(items)) {
          if (key.indexOf("product_") === 0) {
            if (startItem === null) {
              startItem = item;
            }
            products[key.replace("product_", "")] = item.variant_id;
          }
        }
        http
          .fetch("/products/related/" + this.id, {
            products: products,
            related_page_id: product_variants_related_page_id,
          })
          .then((data) => {
            if (data.related_page_id) {
              items.id = data.related_page_id;
              startItem.product_variants_related_page_id = data.related_page_id;
            }
            if (after !== null) {
              after();
            }
          });
      } else {
        let product_variants_related_page_id = items.id;
        if (
          product_variants_related_page_id === null ||
          product_variants_related_page_id === undefined
        ) {
          this.valUpdated(val, "product_", id, items, () =>
            this.valUpdated(val, row, id, items)
          );
          return;
        }
        let data = { related_page_id: product_variants_related_page_id };
        if (row.indexOf("name_") === 0) {
          data["name"] = {};
          data["name"][row.replace("name_", "")] = val;
        } else {
          data[row] = val;
        }
        http
          .fetch("/products/related/" + this.id + "/page", data)
          .then((data) => {
            console.log(data);
          });
      }
    },
    loadData() {
      let url = `/products/related/${this.id}?q=${this.q}`;

      this.firstLoad = false;

      http.fetch(url).then((data) => {
        if (this.rows.length === 0) {
          for (const category of data.productVariantsRelatedCategories) {
            this.rows.push({
              name: category.name,
              key: "product_" + category.product_category_id,
              type: "string",
              show: true,
              component: defineAsyncComponent(() =>
                import("../../components/lists/listItems/ProductSelector")
              ),
            });
          }
          this.rows.push({
            name: "Images",
            key: "images",
            type: "array",
            show: true,
            imageType: "bundle",
            component: defineAsyncComponent(() =>
              import("../../components/lists/listItems/UploadImage")
            ),
          });
          this.rows.push({
            name: "Description",
            key: "description",
            type: "wysiwyg",
            show: true,
          });
          this.rows.push({
            name: "Show in webshop",
            key: "is_shown",
            type: "boolean",
            show: true,
          });
          for (const language of this.languages) {
            this.rows.push({
              name: language.name + " name",
              key: "name_" + language.code,
              type: "string",
              show: true,
            });
          }
        }
        for (const items of data.productVariants) {
          for (const i of Object.keys(items)) {
            if (items[i].image) {
              items[i].image =
                items[i].image.indexOf("http") === 0
                  ? items[i].image
                  : this.mediaServer + "/" + items[i].image;
            }
          }
        }
        if (this.page === 1) {
          this.items = data.productVariants;
        }
        for (const item of this.items) {
          this.itemsById[item[Object.keys(item)[0]].variant_id] = item;
          if (this.page > 1) {
            this.items.push(item);
          }
        }
        this.tableKey++;
      });
    },
  },
};
</script>
