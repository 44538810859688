<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="col-12 text-center my-3"
    >
      <div class="w-100">
        <span class="bold-40">{{
          $t("productHistory.noWarehouseSelected2")
        }}</span>
      </div>
    </div>
    <div class="row mb-5" v-else>
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <TopBar
              v-model:selectAll="selectAll"
              v-model:line-options="lineOptions"
              v-model:rows="rows"
              v-model:items="items"
              v-model:selectedLineOption="selectedLineOption"
              v-model:selectedItems="selectedItems"
              v-on:addNewRow="$refs.addAssetModal.showModal()"
              v-on:delete="deletePartners"
              path="trolleys"
              :show-languages="false"
              :show-currencies="false"
              :methods="methods"
            />
            <DynamicTable
              v-model:rows="rows"
              v-model:items="items"
              v-model:select-all="selectAll"
              v-on:update:val="valUpdated"
              :show-pin="false"
              v-on:selected="(e) => (selectedItems = e)"
              type="trolleys"
              v-on:loadMore="loadMore"
              v-on:order="orderBy"
              :order="order"
              :fix-height="true"
              :key="tableKey"
            />
          </div>
        </div>
      </div>
    </div>
    <NewTrolley
      ref="addAssetModal"
      v-on:add="addNewRow"
      :warehouse="warehouse"
    />
    <TrolleyBoxSizes
      ref="trolleyBoxSizes"
      :sizes="sizes"
      v-on:add="addNewSize"
    />
    <TrolleyBox ref="trolleysBox" v-model="selectedId" :sizes="sizes" />
  </div>
</template>

<script>
import DynamicTable from "@/components/lists/DynamicTable";
import TopBar from "@/components/lists/TopBar";
import { useStore } from "vuex";
import ws from "@/modules/ws";
import http from "@/modules/http";
import lineOptions from "@/modules/lineOptions";
import NewTrolley from "@/components/modals/NewTrolley";
import TrolleyBoxSizes from "../../components/modals/TrolleyBoxSizes.vue";
import TrolleyBox from "../../components/modals/TrolleyBox.vue";

export default {
  name: "Trolleys",
  components: { TrolleyBox, TrolleyBoxSizes, NewTrolley, DynamicTable, TopBar },
  created() {},
  data() {
    return {
      store: useStore(),
      tableKey: 0,
      rows: [],
      filtersTimeOut: null,
      selectedLineOption: 0,
      lineOptions: [],
      selectAll: false,
      items: [],
      selectedItems: [],
      page: 1,
      newCustomRowValue: undefined,
      order: {
        row: null,
        desc: true,
      },
      sizes: [],
      selectedId: null,
      methods: [
        {
          title: "Box sizes",
          icon: "box",
          always: true,
          method: () => {
            this.$refs.trolleyBoxSizes.showModal();
          },
        },
      ],
    };
  },
  computed: {
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    path() {
      return this.$route.path;
    },
    sessionId() {
      return this.store.state.sessionId;
    },
    filters() {
      return this.store.state.filters?.trolleys;
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "trolleys");
    this.loadData();

    ws.init();

    ws.subscribe("add", "trolley", (e) => {
      if (e.session !== this.sessionId) {
        this.items.push(e.object);
        this.tableKey++;
      }
    });

    ws.subscribe("modify", "trolley", (e) => {
      let obj = this.items[this.items.findIndex((o) => o.id === e.objectId)];
      if (obj !== undefined) {
        if (e.session !== this.sessionId) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    window.apps.subscribe("trolleys:packEdit", (id) => {
      this.selectedId = id;
      this.$refs.trolleysBox.showModal();
    });
  },
  watch: {
    warehouse() {
      this.items = undefined;
      this.page = 1;
      this.loadData();
    },
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
  },
  methods: {
    orderBy(order) {
      this.items = undefined;
      this.page = 1;
      this.order = order;
      this.loadData();
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    addNewRow(row) {
      row.selected = this.selectAll;
      if (this.selectAll) {
        this.selectAllDiff++;
      }
      this.items.unshift(row);
      this.tableKey++;
    },
    addNewSize(row) {
      this.sizes.push(row);
    },
    loadData() {
      let url = `/trolleys?page=${this.page}&warehouse=${
        this.warehouse
      }&order[desc]=${this.order.desc ? 1 : 0}&order[row]=${
        this.order.row ? this.order.row : ""
      }`;

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&filter[${filter}][]=${value}`;
            }
          } else {
            url += `&filter[${filter}]=${this.filters[filter].value}`;
          }
        }
      }
      http.fetch(url).then((data) => {
        if (this.page === 1) {
          if (this.rows.length === 0) {
            this.rows = lineOptions.init(data.rows, data.line_options);
            this.lineOptions = data.line_options;
            this.selectedLineOption = data.line_options[0]?.id;
          }
          this.items = data.data;
          this.sizes = data.sizes;
          this.tableKey++;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
      });
    },
    valUpdated(val, row, id, item) {
      let data = {};
      data[row] = val;

      http.fetch(`/trolleys/${item.id}`, data, true, "PUT").then((respond) => {
        console.log(respond);
      });
    },
    deletePartners() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http.fetch(`/trolleys`, { rows: data }, true, "DELETE").then(() => {
        for (const item of this.selectedItems) {
          let i = this.items.indexOf(item);
          if (i > -1) {
            this.items.splice(i, 1);
          }
        }
        this.tableKey++;
      });
    },
  },
};
</script>
